<template>
    <el-main>
        <div class="searchdiv">
            <div class="searchform">
                <el-input v-model="searchinfo.title" :placeholder="$t('notice.category_title')" :label="$t('notice.category_title')" clearable></el-input>
                <el-input v-model="searchinfo.en_title" :placeholder="$t('notice.en_category_title')" :label="$t('notice.en_category_title')" clearable></el-input>
                <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">{{$t('global.search')}}</el-button>
            </div>
            <div>
                <el-button type="primary" @click="adduser('userform')" icon="el-icon-plus">{{ $t('global.add') }}
                  {{ $t('notice.category') }}</el-button>
            </div>
        </div>
        <div class="pagecontent">
            <el-table ref="multipleTable"
                      :data="list"
                      tooltip-effect="dark"
                      style="width: 100%">
                <el-table-column
                        :label="$t('notice.category_title')"
                        width="120" prop="title">
                </el-table-column>
                <el-table-column
                        prop="en_title"
                        :label="$t('notice.en_category_title')"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="remark"
                        :label="$t('notice.remark')"
                        >
                </el-table-column>
                <el-table-column
                        :label="$t('notice.sort')"
                        width="120" prop="sort">
                </el-table-column>
                <el-table-column
                        :label="$t('global.state')"
                        width="120" prop="status">
                    <template slot-scope="scope">
                        <el-link :type="scope.row.status?'success':'danger'"
                                 @click="setenable(scope.row)">{{ scope.row.status?$t('global.enable'):$t('global.disable') }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column
                        :label="$t('notice.created_at')"
                        width="170" prop="created_at">
                </el-table-column>
                <el-table-column :label="$t('global.action')" fixed="right">
                    <template slot-scope="scope">
                        <el-link class="optlink"  @click="edituser(scope.row)">{{$t('global.edit')}}</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @size-change="SizeChange"
                    @current-change="getlist"
                    :current-page="searchinfo.page"
                    :page-sizes="PAGE.pageSizes"
                    :page-size="searchinfo.pagesize"
                    :layout="PAGE.layout"
                    :total="totalRows">
            </el-pagination>
        </div>
        <el-dialog :title="userformdata.id?$t('notice.editNotice'):$t('notice.addNotice')" :visible.sync="isshowdialog" :close-on-click-modal="false">
            <el-form :model="userformdata" :rules="rules" ref="userform" :show-message="false">
                <el-form-item :label="$t('notice.category_title')" label-width="120px" prop="title" required>
                    <el-input v-model="userformdata.title" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('notice.en_category_title')" label-width="120px" prop="en_title" required>
                    <el-input v-model="userformdata.en_title"></el-input>
                </el-form-item>
                <el-form-item :label="$t('notice.sort')" label-width="120px" prop="sort">
                    <el-input v-model="userformdata.sort"></el-input>
                </el-form-item>
                <el-form-item :label="$t('notice.status')" label-width="120px" required>
                    <el-switch v-model="userformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
                </el-form-item>
                <el-form-item :label="$t('notice.remark')" label-width="120px" prop="remark">
                    <el-input type="textarea" v-model="userformdata.remark"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{$t('global.cancel')}}</el-button>
                <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">{{$t('global.submit')}}</el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'user',
        components: {},
        data() {
            return {
                searchinfo: {
                    page:1,
                    pagesize: this.PAGE.pageSize,
                    title: '',
                    en_title:''
                },
                list: [],
                multipleSelection: [],
                totalRows: 0,
                defaultuserinfo: {
                    id: 0,
                    title: '',
                    en_title:'',
                    sort: 0,
                    status: 1,
                    remark: '',
                },
                userformdata: {},
                rules: {
                    title: [
                        { min: 2, message: '语言名称必须在2个字符以上', trigger: 'blur' }
                    ],
                    en_title: [
                        { min: 2, message: '语言别名必须在2个字符以上', trigger: 'blur' }
                    ],
                },
                isshowdialog: false,
                uploadheader: {Authorization:this.$store.getters.getToken},
                isbusy: false,
            }
        },
        methods: {
            SizeChange(val){
                this.searchinfo.pagesize=val;
                this.getlist(1);
            },
            edituser(item){
                this.userformdata=null;
                item.password=undefined;
                this.userformdata = this.cloneData(item);
                this.isshowdialog=true;
            },
            adduser(formName){
                this.userformdata = this.cloneData(this.defaultuserinfo);
                this.isshowdialog=true;
                this.$nextTick(()=>{
                    this.$refs[formName].resetFields();
                });
            },
            async dosearch(){
                this.getlist(1);
            },
            async getlist(page){
                this.searchinfo.page=page||this.searchinfo.page;
                this.isbusy = true;
                let res= await this.httpget('/system/noticeCategory/index',this.searchinfo);
                if(res.code===0){
                    this.list=res.data.data;
                    this.totalRows = res.data.total;
                    this.isbusy = false;
                } else {
                    this.isbusy = false;
                }

            },
            dosubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.suresubmit();
                    } else {
                        return false;
                    }
                });
            },
            async suresubmit(){
                this.isbusy = true;
                let res= await this.httppost('/system/noticeCategory/save',this.userformdata);
                if(res.code===0){
                    this.$message({type: 'success', message: res.msg});
                    this.getlist(1);
                    this.userformdata={};
                    this.isshowdialog=false;
                    this.isbusy = false;
                }else{
                    this.isbusy = false;
                    this.$message({type: 'error', message: res.msg});
                }
            },
            async setenable(item){
                this.$confirm(this.$t('global.areyousure')).then( async () => {
                    this.isbusy = true;
                    let res= await this.httpput('/system/noticeCategory/setenable',{id:item.id});
                    if(res.code===0){
                        this.$message({type: 'success', message: res.msg});
                        let idx=this.list.findIndex((value) => {
                            return value.id === item.id
                        })
                        if(idx!==-1){
                            this.list[idx].status = Math.abs(this.list[idx].status-1);
                        }
                        this.isbusy = false;
                    }else{
                        this.isbusy = false;
                        this.$message({type: 'error', message: res.msg});
                    }
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            },
            async deluser(item){
                this.$confirm(this.$t('global.areyousure')).then( async () => {
                    this.isbusy = true;
                    let res= await this.httpput('/system/noticeCategory/delete',{id:item.id});
                    if(res.code===0){
                        this.$message({type: 'success', message: res.msg});
                        this.getlist();
                    }else{
                        this.$message({type: 'error', message: res.msg});
                    }
                    this.isbusy = false;
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            }
        },
        mounted() {
            this.getlist(1);
        },
        computed: {
            baseurl: {
                get: function() {
                    return this.$store.getters.getSerdomain;
                }
            },
        },
    }
</script>

<style>
    .uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }
    .preview {
        width: 178px;
        height: 50px;
        display: block;
    }
</style>
